import { User } from "@models";
import { TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateUserMutation } from "../../hooks";
import GenericModal from "../GenericModal/GenericModal";

const UserInfo = ({
  user,
  setZoom,
  setIsOnboardingOpen,
}: {
  user: User;
  setZoom: Dispatch<SetStateAction<boolean>>;
  setIsOnboardingOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { orgId = "" } = useParams();
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
  });

  const patchUserMutation = useUpdateUserMutation(orgId);

  const handleUpdateUser = () => {
    console.log(formData);
    patchUserMutation.mutate({ id: user.id, user: formData });
    setIsOnboardingOpen(false);
  };

  return (
    <>
      <GenericModal
        title="Personal Information"
        description="We need some more information to correctly generate content for you."
        isOpen={true}
        isDisabled={!formData.firstName || !formData.lastName}
        handleSave={() => {
          handleUpdateUser();
          setZoom(false);
        }}
      >
        <TextField
          id="firstName"
          label="First Name"
          onChange={(v) =>
            setFormData((prev) => ({ ...prev, firstName: v.target.value }))
          }
          value={formData.firstName}
          fullWidth
        />
        <TextField
          id="lastName"
          label="Last Name"
          onChange={(v) =>
            setFormData((prev) => ({ ...prev, lastName: v.target.value }))
          }
          value={formData.lastName}
          fullWidth
        />
      </GenericModal>
    </>
  );
};

export default UserInfo;
