import {
  BaseUserSchema,
  Device,
  Document,
  DocumentSchema,
  DocumentVersionApproverSchema,
  TEMPLATE_TYPE,
  UserSchema,
  UserTodosSchema,
  deviceSchema,
} from "@models";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Crisp } from "crisp-sdk-web";
import { usePostHog } from "posthog-js/react";
import { queryClient as queryClientAsync } from "src";
import {
  ASSISTANT_CONFIG,
  getApproversQueryKey,
  getDeviceQueryKey,
  getDevicesQueryKey,
  getDocumentQueryKey,
  getDocumentsQueryKey,
  getFileQueryKey,
  getOrganizationUsersQueryKey,
  getTasksQueryKey,
  getUserByIdQueryKey,
  getUserQueryKey,
  getUserTodosQueryKey,
} from "../config";
import {
  getDevice,
  getDevices,
  getDocument,
  getDocumentApprovers,
  getDocuments,
  getFile,
  getUser,
  getUserById,
  getUserTodos,
  getUsersByOrg,
} from "../services";
import { rootStore } from "../stores";

const { deviceStore } = rootStore;

export function useGetUser(enabled: boolean = true) {
  const posthog = usePostHog();
  return useQuery({
    queryKey: getUserQueryKey(),
    queryFn: async () => {
      const { data } = await getUser();
      const user = await UserSchema.validate(data);
      console.log("user", user);
      Crisp.user.setEmail(user.email);
      Crisp.user.setNickname(user.firstName + " " + user.lastName);
      posthog.identify(user.id, {
        email: user.email,
        organizationId: user.organizationId,
        firstName: user.firstName,
        lastName: user.lastName,
      });
      posthog.group("company", user.organizationId);

      return user;
    },
    enabled,
  });
}

export function useGetUserById({ userId = "" }: { userId?: string }) {
  return useQuery({
    queryKey: getUserByIdQueryKey(userId),
    queryFn: async () => {
      const { data } = await getUserById(userId);
      return await BaseUserSchema.validate(data);
    },
    enabled: !!userId,
  });
}

export function useGetUserTodos(deviceId: string, enabled: boolean = false) {
  return useQuery({
    queryKey: getUserTodosQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getUserTodos(deviceId);
      return await UserTodosSchema.validate(data);
    },
    enabled,
  });
}

export function useGetDocumentApproversQuery({
  documentId,
  versionId,
}: {
  documentId: string;
  versionId: string;
}) {
  return useQuery({
    queryKey: getApproversQueryKey(documentId, versionId),
    queryFn: async () => {
      const { data } = await getDocumentApprovers({ documentId, versionId });
      return await Promise.all(
        data.map(
          async (approver: any) =>
            await DocumentVersionApproverSchema.validateSync(approver)
        )
      );
    },
  });
}

export function useGetUsersByOrg(params: {
  orgId: string;
  skip?: number;
  take?: number;
}) {
  return useQuery({
    queryKey: getOrganizationUsersQueryKey({
      orgId: params.orgId,
      skip: params.skip,
      take: params.take,
    }),
    queryFn: async () => {
      const { data } = await getUsersByOrg(params);
      return await Promise.all(
        data.users.map(async (user: any) => await BaseUserSchema.validate(user))
      );
    },
  });
}

export function useGetDevices() {
  return useQuery({
    queryKey: getDevicesQueryKey(),
    queryFn: async () => {
      const { data } = await getDevices();
      return Promise.all(
        data.map(async (device: Device) => {
          const validated = await deviceSchema.validate(device);
          return validated;
        })
      );
    },
  });
}

export function useGetDevice(deviceId: string) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: getDeviceQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getDevice(deviceId);
      return await deviceSchema.validate(data);
    },
    initialData: () => {
      const devices: Device[] | undefined = queryClient.getQueryData([
        "devices",
      ]);
      return devices?.find((device: Device) => device.id === deviceId);
    },
  });
}

const getDocumentsParams = (deviceId: string) => {
  return {
    queryKey: getDocumentsQueryKey(deviceId),
    queryFn: async () => {
      const { data } = await getDocuments(deviceId);
      const docs: Document[] = await Promise.all(
        data.map(async (d: any) => await DocumentSchema.validateSync(d))
      );
      return docs.filter((doc) => ASSISTANT_CONFIG[doc.name as TEMPLATE_TYPE]);
    },
    staleTime: 1000 * 60,
  };
};

export function useGetDocuments(deviceId: string) {
  return useQuery(getDocumentsParams(deviceId));
}

export function getDocumentsAsync(deviceId: string) {
  return queryClientAsync.fetchQuery(getDocumentsParams(deviceId));
}

const getTasksQueryParams = (deviceId: string) => {
  return {
    queryKey: getTasksQueryKey(deviceId),
    queryFn: () => deviceStore.fetchRoadmapTasks(deviceId),
    staleTime: 1000 * 60,
  };
};

// To allow passing data do options?: QueryOptions<RoadmapTasks[]> Passing the type of the data is important otherwise react-query will not know what type of data to expect
export function useGetTasks(deviceId: string) {
  return useQuery(getTasksQueryParams(deviceId));
}

export function getTasksAsync(deviceId: string) {
  return queryClientAsync.fetchQuery(getTasksQueryParams(deviceId));
}

export const useGetDocument = (deviceId: string, docId: string = "") => {
  return useQuery({
    queryKey: getDocumentQueryKey(deviceId, docId),
    queryFn: async () => {
      const { data, status } = await getDocument(deviceId, docId);
      if (status !== 200) {
        return null;
      }
      return DocumentSchema.validate(data);
    },
    enabled: !!docId,
    staleTime: 1000 * 60,
  });
};

export const useGetFile = ({ fileId }: { fileId: string }) => {
  return useQuery({
    queryKey: getFileQueryKey(fileId),
    queryFn: async () => {
      const data = await getFile({ fileId });
      // return FileSchema.validate(data);
      return data;
    },
    enabled: !!fileId,
  });
};
